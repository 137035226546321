@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Helvetica";
  src: local("Roboto (1)"), local("Roboto (1)"),
    url("../public/fonts/Roboto (1)/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Helvetica";
  src: local("Roboto (1)"), local("Roboto (1)"),
    url("../public/fonts/Roboto (1)/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

html::-webkit-scrollbar {
  @apply w-2;
}

html::-webkit-scrollbar-track {
  @apply bg-slate-300;
}

html::-webkit-scrollbar-thumb {
  @apply bg-slate-400 rounded-full;
}

/* Sidebar Start */
.sidebar {
  @apply fixed left-0 top-0 w-64 min-h-screen h-full py-6 bg-white text-slate-950 dark:bg-slate-800 dark:text-slate-50 z-[100] overflow-auto;
}

.sidebar.close {
  @apply w-20 overflow-visible;
}

.sidebar button img {
  @apply w-28;
}

.sidebar.close button img {
  @apply w-16;
}

.home-section {
  @apply relative left-0 top-0 min-h-screen h-full transition-all duration-300 ease-in-out bg-white;
  width: calc(100% - 260px);
  left: 260px;
}

.sidebar.close ~ .home-section {
  width: calc(100% - 84px);
  left: 84px;
  transistion: all 0.5s ease;
}

.sidebar.close ul li a div span {
  @apply hidden;
}

.sidebar.close ul li a .arrow-down {
  @apply hidden;
}

.sidebar ul li a .arrow-down {
  @apply transition-all duration-200 ease-linear;
}

.sidebar ul li.show a .arrow-down {
  transform: rotate(-180deg);
  @apply transition-all duration-200 ease-linear;
}

.item-menuActive {
  @apply text-sekunder-60;
}

.item-menuBase {
  @apply dark:text-slate-50 text-slate-800;
}

.sidebar ul li .sub-menu .title-submenu {
  @apply hidden text-sm font-semibold uppercase;
}

.sidebar.close ul li .sub-menu .title-submenu {
  @apply block;
}

.sidebar.close ul li .sub-menu {
  @apply block opacity-0 absolute top-0 left-full pointer-events-none bg-white px-6 py-4;
}

.sidebar.close ul li:hover .sub-menu {
  @apply min-w-max w-full block absolute opacity-100 -top-3 left-full pointer-events-auto dark:bg-slate-800 bg-white shadow-normal rounded-r-lg px-6 py-4;
}

.sidebar ul li .sub-menu {
  @apply hidden pl-8;
}

.menuActive {
  @apply border-l-4 border-sekunder-60 bg-sekunder-20;
}

.item-sub-menuActive {
  @apply inline-block py-1 hover:text-sekunder-40 transition-all duration-300 ease-in-out text-sekunder-60 text-xs;
}

.item-sub-menuBase {
  @apply inline-block py-1 hover:text-sekunder-60 transition-all duration-300 ease-in-out text-slate-500 dark:text-slate-300 dark:hover:text-sekunder-60 text-xs;
}

.sidebar ul li.show .sub-menu {
  @apply block;
}

.sidebar.close ul li .sub-menu {
  @apply block opacity-0 absolute top-0 left-full pointer-events-none bg-slate-50 px-6 py-4;
}

.sidebar ul li .sub-menu .title-submenu {
  @apply hidden text-xs font-semibold uppercase;
}

.sidebar .btnSidebarBack {
  @apply w-full hidden;
}

.menu-sidebar-active {
  @apply text-sekunder-60;
}

@media (max-width: 1024px) {
  .sidebar {
    @apply -left-full bg-white text-slate-950 dark:bg-slate-800 dark:text-slate-50 transition-all duration-300 ease-in-out;
  }

  .sidebar.close {
    @apply left-0 w-4/5 shadow-sidebar-shadow transition-all duration-300 ease-in-out overflow-auto;
  }

  .sidebar.close ~ .home-section {
    @apply left-0 w-full transition-all duration-300 ease-in-out;
  }

  .home-section {
    @apply left-0 w-full transition-all duration-300 ease-in-out;
  }

  .sidebar.close button img {
    @apply w-32;
  }

  .sidebar.close ul li a div span {
    @apply block;
  }

  .sidebar.close ul li a .arrow-down {
    @apply block;
  }

  .sidebar.close ul li .sub-menu .title-subMenu {
    @apply hidden;
  }

  .sidebar.close ul li a span {
    @apply block;
  }

  .sidebar.close ul li a svg {
    @apply mr-3;
  }

  .sidebar.close ul li a svg.arrow-down {
    @apply block;
  }

  .sidebar.close ul li.show a span {
    @apply block;
  }

  .sidebar.close ul li:hover .sub-menu {
    @apply w-full static hidden  opacity-100 pointer-events-auto bg-white px-6 py-4;
  }

  .sidebar.close ul li.show:hover .sub-menu {
    @apply w-full block static pl-11 pt-0 pr-0 pb-0  opacity-100 pointer-events-auto dark:bg-transparent bg-white shadow-none rounded-none px-6 py-4;
  }

  .sidebar.close ul li .sub-menu {
    @apply hidden pointer-events-none dark:bg-transparent bg-white px-6 py-4;
  }

  .sidebar.close ul li.show .sub-menu {
    @apply w-full block static pl-11 pt-0 pr-0 pb-0 opacity-100 pointer-events-none dark:bg-transparent bg-white px-6 py-4;
  }

  .sidebar.close ul li .sub-menu .title-submenu {
    @apply hidden;
  }

  .sidebar .btnSidebarBack {
    @apply w-full hidden transition-opacity duration-500 ease-in-out;
  }

  .sidebar.close .btnSidebarBack {
    @apply w-full block opacity-100 transition-opacity duration-500 ease-in-out;
  }

  .menu-sidebar-active {
    @apply border-none;
  }
}

/* Sidebar End */

.css-1q04gal-MuiDateCalendar-root {
  margin: 0 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="range"]::-webkit-slider-thumb {
  @apply bg-white;
}

.modal-container {
  background-color: rgba(11, 76, 56, 0.2);
  @apply transition-transform duration-300 ease-in-out scale-100;
}

.show-modal {
  @apply transition-transform duration-300 ease-in-out;
  animation: show_modal 0.6s ease;
}

@keyframes show_modal {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.show-modal.active {
  @apply transition-transform duration-300 ease-in-out;
  animation: show_modal 0.5s ease-in-out;
}

.select-box.active .menu-list {
  pointer-events: auto;
  display: block;
}

.select-box.active .show-menu .svg {
  transform: rotate(180deg);
  transition-property: transform;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.dropdown-menu.active {
  @apply block transition-all duration-200 ease-linear pointer-events-auto;
}

.user-select-disabled {
  user-select: none;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 1.5rem !important;
}

div:where(.swal2-container) .swal2-html-container {
  font-size: 1rem !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #169870 !important;
  border: none !important;
  outline: none !important;
  ring: none !important;
  box-shadow: none !important;
}

div:where(.swal2-container) img:where(.swal2-image) {
  margin: 2em auto 0em !important;
}

.btn-pagination {
  @apply flex items-center justify-center h-10 w-10 mr-2 rounded-lg bg-[#eefbf5] text-[#169870] hover:bg-[#169870] hover:text-white;
}

.btn-pagination.active {
  @apply bg-[#169870] text-white;
}
